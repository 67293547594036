@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

 html,
 body,
  #root {
    min-height: 100%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance:textfield;
  }

  body{
    background: #fff  no-repeat;
    background-size: cover;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 14px;
  }

  /* ----------------------------- Icone Whatsapp ----------------------------- */

  #input_1_0 {
    display:none;
  }

  #input_1_0 + label {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    display:inline-block;
  }

  input#input_1_0 + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220378.svg');
  }

  input#input_1_0:checked + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220236.svg');
  }

  /* ----------------------------- Icone de Seleção ----------------------------- */

  #input_1_1 {
    display:none;
  }

  #input_1_1 + label {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 27px;
    width: 27px;
    display:inline-block;
  }

  input#input_1_1 + label {
    background-image: url('https://image.flaticon.com/icons/svg/149/149036.svg');
  }

  input#input_1_1:checked + label {
    background-image: url('https://image.flaticon.com/icons/svg/149/149035.svg');
  }
  
  /* ----------------------------- Icone de Parcelamento ----------------------------- */

  #input_1_2 {
    display:none;
  }

  #input_1_2 + label {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    display:inline-block;
  }

  input#input_1_2 + label {
    background-image: url('https://image.flaticon.com/icons/svg/152/152593.svg');
  }

  input#input_1_2:checked + label {
    background-image: url('https://image.flaticon.com/icons/svg/226/226972.svg');
  }
   
  /* ----------------------------- Icone de Parcelamento ----------------------------- */

    #input_1_5 {
      display:none;
    }
  
    #input_1_5 + label {
      background-size:cover;
      background-position:center;
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
      display:inline-block;
    }
  
    input#input_1_5 + label {
      background-image: url('https://image.flaticon.com/icons/svg/152/152593.svg');
    }
  
    input#input_1_5:checked + label {
      background-image: url('https://image.flaticon.com/icons/svg/226/226972.svg');
    }

  /* ----------------------------- Icone de Whatsapp 2 ----------------------------- */

  #input_1_3 {
    display:none;
  }

  #input_1_3 + label {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    display:inline-block;
  }

  input#input_1_3 + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220378.svg');
  }

  input#input_1_3:checked + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220236.svg');
  }

    /* ----------------------------- Icone de Whatsapp 3 ----------------------------- */

    #input_1_4 {
      display:none;
    }
  
    #input_1_4 + label {
      background-size:cover;
      background-position:center;
      background-repeat: no-repeat;
      height: 25px;
      width: 25px;
      display:inline-block;
    }
  
    input#input_1_4 + label {
      background-image: url('https://image.flaticon.com/icons/svg/220/220378.svg');
    }
  
    input#input_1_4:checked + label {
      background-image: url('https://image.flaticon.com/icons/svg/220/220236.svg');
    }

    /* ----------------------------- Icone Whatsapp 5 ----------------------------- */

  #input_1_5 {
    display:none;
  }

  #input_1_5 + label {
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    display:inline-block;
  }

  input#input_1_5 + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220378.svg');
  }

  input#input_1_5:checked + label {
    background-image: url('https://image.flaticon.com/icons/svg/220/220236.svg');
  }